<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed @click="$router.go(-1)"
          ><v-icon left>mdi-arrow-left</v-icon>Back</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" class="pr-12">
          <h1>INDIVIDUAL APPLICATION</h1>
        </v-col>
      </v-row>
      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3" height="auto">
          <v-list-item class="pl-0 pr-0" three-line>
            <v-row no-gutters>
              <v-col cols="7">
                <v-list-item-content>
                  <v-list-item-title class="title text-wrap">
                    {{ form.customer.full_name }}

                    <v-icon left>mdi-map-marker</v-icon>
                    <span v-if="form.property_id">
                      {{ form.property.full_address }}
                    </span>
                    <span v-else> {{ form.address_text }}</span>

                    <span v-if="form.property_id">
                      <v-icon left class="ml-2">mdi-cash</v-icon>£{{
                        formatPrice(form.property.monthly_rent)
                      }}p/m
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="5">
                Status: {{ form.form_status }}
                <div class="text-right">
                  <v-btn
                    v-if="form.guarantor_email"
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    class="mr-2"
                    @click="openSendGuarantor(form)"
                  >
                    Send to Guarantor
                  </v-btn>

                  <v-btn
                    v-if="
                      form.form_status == 'Verified' ||
                      form.form_status == 'Exported to Agent OS'
                    "
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    class="mr-2"
                    @click="openSendAgentos(form)"
                  >
                    Send to Agent OS
                  </v-btn>

                  <v-btn
                    v-if="form.form_status == 'Submitted'"
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    class="mr-2"
                    @click="
                      $refs.statusDialog.changeStatusPrompt(
                        'Verified',
                        null,
                        true
                      )
                    "
                  >
                    Verify
                  </v-btn>

                  <v-btn
                    v-if="form.form_status == 'Submitted'"
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    @click="
                      $refs.statusDialog.changeStatusPrompt(
                        'Returned',
                        null,
                        true
                      )
                    "
                  >
                    Return
                  </v-btn>

                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    class="mr-2"
                    @click="
                      $refs.statusDialog.changeStatusPrompt(
                        'Withrawn',
                        null,
                        true
                      )
                    "
                  >
                    Withdraw
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-toolbar>

        <v-row>
          <v-col cols="12">
            <div class="mt-5">
              <v-toolbar flat color="grey lighten-3" height="auto">
                <v-tabs>
                  <v-tab
                    :to="{
                      name: `module-easylets-applicationforms-individual`,
                    }"
                    >Tenant Details</v-tab
                  >
                  <v-tab
                    v-if="form.tenant_type == 'professional'"
                    :to="{
                      name: `module-easylets-applicationforms-individual-financial-info`,
                    }"
                    >Financial</v-tab
                  >
                  <v-tab
                    :to="{
                      name: `module-easylets-applicationforms-individual-additional-info`,
                    }"
                    >Additional Info</v-tab
                  >
                  <v-tab
                    :to="{
                      name: `module-easylets-applicationforms-individual-address`,
                    }"
                    >Current Address</v-tab
                  >
                  <v-tab
                    :to="{
                      name: `module-easylets-applicationforms-individual-landlord`,
                    }"
                    >Current Landlord</v-tab
                  >
                  <v-tab
                    :to="{
                      name: `module-easylets-applicationforms-individual-guarantor`,
                    }"
                    >Guarantor</v-tab
                  >
                  <v-tab
                    v-if="form.tenant_type == 'professional'"
                    :to="{
                      name: `module-easylets-applicationforms-individual-employment-info`,
                    }"
                    >Employment</v-tab
                  >

                  <v-tab
                    :to="{
                      name: `module-easylets-applicationforms-individual-next-of-kin`,
                    }"
                    >Next of Kin</v-tab
                  >

                  <v-tab
                    :to="{
                      name: `module-easylets-applicationforms-individual-upload`,
                    }"
                    >Uploaded Files</v-tab
                  >
                </v-tabs>
              </v-toolbar>
              <router-view></router-view>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mt-2">
        <v-col cols="4">
          <v-card outlined>
            <v-card-title class="grey lighten-3">Audit Trail</v-card-title>
            <v-card-text class="pt-5 grey lighten-4">
              <div
                class="mt-2"
                v-for="audit in form.audits"
                v-bind:key="audit.form_audit_id"
              >
                <h3>{{ formatDate(audit.audit_date) }}</h3>
                {{ audit.audit_text }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title class="grey lighten-3">Comments</v-card-title>
            <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
              <v-form @submit.prevent="save" method="post" id="comment-form">
                <v-text-field
                  label="Add a comment"
                  type="textarea"
                  v-model="fields.comment_text"
                  outlined
                  background-color="white"
                ></v-text-field>
              </v-form>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="accent"
                  text
                  type="submit"
                  :loading="loading"
                  form="comment-form"
                  >Send</v-btn
                >
              </v-card-actions>
              <div
                class="mt-2"
                v-for="comment in form.comments"
                v-bind:key="comment.form_comments_id"
              >
                <h3>
                  {{ formatDate(comment.comment_date_time) }}
                  {{ comment.user.full_name }}
                </h3>
                {{ comment.comment_text }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title class="grey lighten-3"
              >Confidential notes</v-card-title
            >
            <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
              <v-form
                @submit.prevent="saveConfidential"
                method="post"
                id="confidential-form"
              >
                <v-text-field
                  label="Add a comment"
                  type="textarea"
                  v-model="confidentialForm.fields.comment_text"
                  outlined
                  background-color="white"
                ></v-text-field>
              </v-form>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="accent"
                  text
                  type="submit"
                  :loading="confidentialForm.loading"
                  form="confidential-form"
                  >Send</v-btn
                >
              </v-card-actions>
              <div
                class="mt-2"
                v-for="comment in form.confidential"
                v-bind:key="comment.form_comments_id"
              >
                <h3>
                  {{ formatDate(comment.comment_date_time) }}
                  {{ comment.user.full_name }}
                </h3>
                {{ comment.comment_text }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="sendAgentos.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Send to agentos</v-card-title>
        <v-card-text>Are you sure you wish to send ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="sendAgentos.loading"
            @click="sendAgentosConfirm"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendGuarantor.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Send to Guarantor</v-card-title>
        <v-card-text>Are you sure you wish to send?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetGuarantor"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="sendGuarantor.loading"
            @click="sendFormGuarantor"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <status-dialog ref="statusDialog" />
  </div>
</template>

<script>
import moment from "moment";
import StatusDialog from "./components/StatusDialog.vue";

export default {
  components: {
    StatusDialog,
  },
  data() {
    return {
      pdfLoading: false,
      breadcrumbs: [
        {
          text: "Application Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
      loading: false,
      errors: {},
      fields: {
        comment_text: null,
        is_internal: 0,
      },
      sendAgentos: {
        dialog: false,
        customer: {},
        loading: false,
      },
      sendGuarantor: {
        dialog: false,
        customer: {},
        loading: false,
      },
      confidentialForm: {
        loading: false,
        errors: {},
        dialog: false,
        fields: {
          comment_text: null,
          is_internal: 1,
        },
      },
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },

  methods: {
    openSendAgentos(form) {
      this.sendAgentos.form = form;
      this.sendAgentos.dialog = true;
    },

    openSendGuarantor(form) {
      this.sendGuarantor.form = form;
      this.sendGuarantor.dialog = true;
    },

    resetDelete() {
      this.sendAgentos.dialog = false;
      this.sendAgentos.form = {};
      this.sendAgentos.loading = false;
    },

    sendFormGuarantor() {
      this.sendGuarantor.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/sendGuarantor", {
          appId: this.$route.params.id,
          formId: this.sendGuarantor.form.application_id,
        })
        .then(() => {
          this.resetGuarantor();
        })
        .catch(() => {
          this.sendGuarantor.loading = false;
        });
    },

    resetGuarantor() {
      this.sendGuarantor.dialog = false;
      this.sendGuarantor.form = {};
      this.sendGuarantor.loading = false;
    },

    sendAgentosConfirm() {
      this.sendAgentos.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/sendAgentos", {
          appId: this.$route.params.id,
          formId: this.sendAgentos.form.application_id,
        })
        .then(() => {
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Data was send to AgentOs",
            color: "orange",
          });

          this.resetDelete();
        })
        .catch(() => {
          this.sendAgentos.loading = false;
        });
    },
    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        formId: this.$route.params.formId,
        fields: this.fields,
      };

      this.$store
        .dispatch("easylets/applicationformsStore/saveComment", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    saveConfidential() {
      this.confidentialForm.loading = true;
      this.confidentialForm.errors = {};

      this.confidentialForm.fields.is_internal = 1;

      let payload = {
        appId: this.$route.params.id,
        formId: this.$route.params.formId,
        fields: this.confidentialForm.fields,
      };

      this.$store
        .dispatch("easylets/applicationformsStore/saveComment", payload)
        .then(() => {
          this.confidentialForm.loading = false;
          this.confidentialForm.fields = {
            comment_text: null,
            is_internal: 1,
          };
        })
        .catch((err) => {
          this.confidentialForm.errors = err.response.data.errors;
          this.confidentialForm.loading = false;
        });
    },
    reset() {
      this.loading = false;
      this.fields = {
        comment_text: null,
        is_internal: 0,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
    formatDate(dateString) {
      return moment(dateString).format("DD/MM/YYYY HH:mm");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
